import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import GlobalContext from "../context/GlobalContext";
import { Button, Section, Input, Select } from "../components/Core";
import { navigate } from "gatsby"
import { Container, Col, Row } from "react-bootstrap";
import Seo from "../components/Seo";
import { Form, Formik } from 'formik';
import { GetSchemaColumns } from '../api/schemeApi';
import ReactStars from 'react-stars'
import API from '../api/api';

const TextareaInput = styled.input`
  border: 1px solid rgb(204, 204, 204);
  width: 100%;
  border-radius: 10px;
  display: block;
  resize: none;
  padding-left: 24px;
  padding-top: 16px;
  font-size: 16px;
  font-family: 'Arial';
  line-height: 24px;
  color: rgb(51, 51, 51);
  &:focus {
    border: 1px solid #2684ff;
    outline: none;
  }
`;

const StyledButton = styled(Button)`
  &:disabled {
    background-color: #d3d3d3;
    cursor: not-allowed;
    &:hover {
      background-color: #d3d3d3;
    cursor: not-allowed;
    }
  }
`;

const CSATPage = () => {
    const gContext = useContext(GlobalContext);
    const [rating, setRating] = useState(null);
    const [caseId, setCaseId] = useState(null);
    const [reason, setReason] = useState('');
    const [reasons, setReasons] = useState('');
    const [comment, setComment] = useState('');

    useEffect(() => {
      const fetchSchemaColumns = async () => {
        try {
          const resp = await GetSchemaColumns("SupportModule", "Case", "CsatReason");
          setReasons(resp.options)
        }
        catch (error) {
          console.log('%c Get Schema Columns Error: ', 'color:red', error);
        };
      };
      fetchSchemaColumns();

      if (typeof window !== 'undefined' && window) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        setCaseId(urlParams.get('CaseId'));
      }
    } ,[])

    const submitForm = () => {
      
      try {
        API.put(`SupportModule/v1.0/db/Case/${caseId}`, {
          entity: "SupportModule:Case",
          schemaId: process.env.CASE_SCHEMA_ID,
          properties: {
            CsatScore: rating,
            CsatReason: reason,
            CsatComment: comment
          }
        })
          .then((resp) => {
            navigate('/success-csat')
          })
          .catch((error) => {
            console.log('error: ', error)
          });
      } catch (e) {
        console.log('error: ', e)
      }
    }

    return (
      <>
        <Seo page="csat" />
          <Section>
              <Container>
                <Row>
                  <Col sm={12} md={10} lg={8} xl={6} className="centered">
                    <h2 className="text-center">Satisfaction survey.</h2>
                    <p className="text-left mt-3 mb-0">Are you satisfied with our service?</p>
                    <ReactStars
                      size={30}
                      count={5}
                      half={false}
                      value={rating}
                      color2={'#08de9e'}
                      onChange={(newValue) => {
                        setRating(newValue)
                      }}
                    />

                    {rating && rating < 3 &&
                      <>
                        <p className="text-left mt-2">What is the main reason you are not satisfied?</p>
                        <Select
                          autocomplete="off"
                          className="regular-select"
                          menuOnTop={true}
                          defaultValue=""
                          name="reason"
                          options={reasons}
                          isSearchable={false}
                          placeholder=""
                          onChange={option => setReason(option.value)}
                        />
                      </>
                    }
                    

                    <p className="text-left mt-2">Please add comment about our support</p>
                    <TextareaInput
                      type=""
                      as="textarea"
                      height="150px"
                      name="comment"
                      rows="7"
                      placeholder=""
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <StyledButton
                      type="submit"
                      borderRadius={10}
                      className="blue-bg mt-4"
                      onClick={submitForm}
                      disabled={!rating}
                    >
                      Submit
                    </StyledButton>
                  </Col>
                </Row>
              </Container>
          </Section>
      </>
    )
}

export default CSATPage