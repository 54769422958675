import API from ".";

export const GetSchemaColumns = async (moduleName:string, entityName:string, columnName:string = "" ): 
    Promise<string[]> => API.get(`/SchemaModule/v1.0/schemas/bymodule?moduleName=${moduleName}&entityName=${entityName}`)
    .then((resp: any) => {
        return resp.data.data.columns.find(item => item.name.includes(columnName));
    })
    .catch((error: any) => {
        console.log('error: ', error);
        return null;
    });
